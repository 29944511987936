import React from "react";
import style from "./Login.module.scss";
import ClipLoader from "react-spinners/ClipLoader";
import useStore from "./Login.store";
import { useLogin } from "./useLogin";

export default function Login() {
  const { apiResponse, isOtpSent, showOtpText, formData } = useStore();
  const { handleChangeForm, handleSubmitForm, validationError, timer, handleResendOtp } =
    useLogin();

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  return (
    <div className={style.wrapper}>
      <form onChange={handleChangeForm} onSubmit={handleSubmitForm}>
        <h1>Please Login to access tests</h1>

        {!isOtpSent ? (
          <div className={style.row}>
            <label>Phone Number</label>
            <input
              type="text"
              name="username"
              value={formData.username || ""}
              placeholder="Enter Here"
              pattern="\d*"
              maxLength="10"
            />
          </div>
        ) : (
          <div className={style.row}>
            <label>OTP</label>
            <input
              type="password"
              name="password"
              value={formData.password || ""}
              placeholder="Enter Here"
              pattern="\d*"
            />
            {showOtpText && <p>Please enter OTP sent to {formData.username}</p>}
          </div>
        )}

        {validationError && <p className={style.error}>{validationError}</p>}

        <div className={style.row}>
          <button type="submit" disabled={apiResponse.isLoading}>
            {apiResponse.isLoading ? (
              <ClipLoader color={"white"} loading={true} size={10} />
            ) : isOtpSent ? (
              "Submit"
            ) : (
              "Send OTP"
            )}
          </button>
        </div>

        {isOtpSent && (
          <div className={style.row}>
            <button onClick={handleResendOtp} disabled={timer > 0}>
              Resend OTP {timer > 0 && `(${formatTime(timer)})`}
            </button>
          </div>
        )}

        {apiResponse.isError && (
          <div>
            <p className={style.error}>{apiResponse.message}</p>
            {apiResponse.message === "User not registered!" && (
              <p>
                Please <a href="/signup">sign up</a>.
              </p>
            )}
          </div>
        )}
      </form>
    </div>
  );
}
