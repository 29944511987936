import { create } from "zustand";

const useStore = create((set) => ({
  formData: {},
  setFormData: (payload) => set(() => ({ formData: payload })),

  isOtpSent: false,
  setIsOtpSent: (payload) => set(() => ({ isOtpSent: payload })),

  showOtpText: false,
  setShowOtpText: (payload) => set(() => ({ showOtpText: payload })),

  validationError: "",
  setValidationError: (error) => set({ validationError: error }),

  apiResponse: {
    isLoading: false,
    isError: false,
    message: "",
  },
  setApiResponse: (payload) => set(() => ({ apiResponse: payload })),
}));

export default useStore;
