import { useState, useEffect } from "react";
import { postLogin, postLoginOtp } from "api/user";
import useStore from "./Login.store";

export function useLogin() {
  const { formData, setFormData, setApiResponse, isOtpSent, setIsOtpSent, setShowOtpText } =
    useStore();
  const [validationError, setValidationError] = useState("");
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [timer]);

  function handleChangeForm(e) {
    const { name, value } = e.target;
    if (name === "username" && !/^\d*$/.test(value)) return;
    if (name === "password" && !/^\d*$/.test(value)) return;
    setFormData({ ...formData, [name]: value });
  }

  async function handleSubmitForm(e) {
    e.preventDefault();

    if (!isOtpSent) {
      if (!formData.username || formData.username.length !== 10) {
        setValidationError("Phone number must be a 10-digit number.");
        return;
      }
    } else {
      if (!formData.password || formData.password.length !== 6) {
        setValidationError("Please enter a 6-digit OTP.");
        return;
      }
    }

    setValidationError("");
    setApiResponse({ isLoading: true, isError: false, message: "" });

    try {
      if (!isOtpSent) {
        const postBody = { username: formData.username };
        await postLoginOtp(postBody);
        setApiResponse({ isLoading: false, isError: false, message: "" });
        setIsOtpSent(true);
        setShowOtpText(true);
        setTimer(60);
      } else {
        setShowOtpText(false);
        const resp = await postLogin(formData);
        localStorage.setItem("authToken", resp.data.data.token);
        window.location.href = "/";
        setApiResponse({ isLoading: false, isError: false, message: "" });
      }
    } catch (err) {
      if (err.response?.status === 401) {
        setApiResponse({
          isLoading: false,
          isError: true,
          message: "Incorrect OTP",
        });
      } else {
        setApiResponse({
          isLoading: false,
          isError: true,
          message: "User not registered!",
        });
      }
    }
  }

  async function handleResendOtp() {
    try {
      const postBody = { username: formData.username };
      await postLoginOtp(postBody);
      setApiResponse({ isLoading: false, isError: false, message: "" });
      setTimer(60);
    } catch (err) {
      setApiResponse({
        isLoading: false,
        isError: true,
        message: "Failed to resend OTP.",
      });
    }
  }

  return {
    handleChangeForm,
    handleSubmitForm,
    validationError,
    timer,
    handleResendOtp,
  };
}
